// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    text-align: justify;
    z-index: index 12;;
  }
  
  .image-container {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 600px;
  }
  
  .image1 {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .title {
    text-align: center;
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: 8px;
    width: 25rem;
  }
   .text1{
    padding-top: 5rem;
   }
  .text1, .text2, .text3,.text4{
    padding-left:2rem;
    padding-right:2rem;
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
  }
  .style1{
    display: inline-block; /* Nécessaire pour appliquer la bordure uniquement au texte */
    border-bottom: 3px solid #FFD700; /* Bordure pour le soulignement */
  }
  .style2{
    background-color: #FFD700; /* Couleur de surlignage (jaune/orange) */
    color: black; /* Optionnel : s'assurer que le texte reste lisible */
    padding: 1px 1px; /* Optionnel : espace autour du texte pour améliorer le rendu */
    border-radius: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Accueil/Accueil.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;EACd;GACC;IACC,iBAAiB;GAClB;EACD;IACE,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB;EACA;IACE,qBAAqB,EAAE,6DAA6D;IACpF,gCAAgC,EAAE,iCAAiC;EACrE;EACA;IACE,yBAAyB,EAAE,yCAAyC;IACpE,YAAY,EAAE,qDAAqD;IACnE,gBAAgB,EAAE,+DAA+D;IACjF,mBAAmB;EACrB","sourcesContent":[".container {\n    text-align: justify;\n    z-index: index 12;;\n  }\n  \n  .image-container {\n    position: relative;\n    display: inline-block;\n    width: 100%;\n    max-width: 600px;\n  }\n  \n  .image1 {\n    width: 100%;\n    height: auto;\n    display: block;\n  }\n  \n  .title {\n    text-align: center;\n    position: absolute;\n    top: 18%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: black;\n    font-size: 1.5rem;\n    padding: 10px 20px;\n    border-radius: 8px;\n    width: 25rem;\n  }\n   .text1{\n    padding-top: 5rem;\n   }\n  .text1, .text2, .text3,.text4{\n    padding-left:2rem;\n    padding-right:2rem;\n    font-size: 1rem;\n    color: #555;\n    margin-top: 10px;\n  }\n  .style1{\n    display: inline-block; /* Nécessaire pour appliquer la bordure uniquement au texte */\n    border-bottom: 3px solid #FFD700; /* Bordure pour le soulignement */\n  }\n  .style2{\n    background-color: #FFD700; /* Couleur de surlignage (jaune/orange) */\n    color: black; /* Optionnel : s'assurer que le texte reste lisible */\n    padding: 1px 1px; /* Optionnel : espace autour du texte pour améliorer le rendu */\n    border-radius: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
