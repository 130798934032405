// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1rem;
    text-align: left;
  }
  
  .location-table th,
  .location-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .location-table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .location-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .location-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .location-table th {
    text-align: center;
  }
h6{
    margin-top:-1rem;
} 
.date{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Localisation.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;EACpB;AACF;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".location-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 20px 0;\n    font-size: 1rem;\n    text-align: left;\n  }\n  \n  .location-table th,\n  .location-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .location-table th {\n    background-color: #f4f4f4;\n    color: #333;\n  }\n  \n  .location-table tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .location-table tr:hover {\n    background-color: #f1f1f1;\n  }\n  \n  .location-table th {\n    text-align: center;\n  }\nh6{\n    margin-top:-1rem;\n} \n.date{\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
